import { Layout } from './Layout';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Users } from './pages/Users';
import { Rooms } from './pages/Rooms';
import { Room } from './pages/Room';
import { Login } from './pages/Login';
import { Histories } from './pages/Histories';
import { History } from './pages/History';

function App() {
  const token = localStorage.getItem('token');
  return (
    <BrowserRouter>
      {token ? (
        <Layout>
          <Routes>
            <Route path='/' Component={Home} />

            <Route path='/users' Component={Users} />
            <Route path='/rooms' Component={Rooms} />
            <Route path='/rooms/:id' Component={Room} />
            <Route path='/seller/history' Component={Histories} />
            <Route path='/seller/history/:id' Component={History} />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path='/' element={<Navigate replace to='/login' />} />
          <Route path='/login' Component={Login} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
