import "src/styles/chat.style.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/chat.style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWQW+bMBS+51c8aZrUHhwZ0pCEXCZNm3bYbj1Oqhz8CG7BRsYU0qn/fcIEQoJpu1UVF/Sw/b73fZ/fYx4lzPyeF+aQ4l2kpGFCor67K+91uXmi8GcGkKDYJyaEiKXRlUfpYwIE1jSvr7czgJ3SHDXRjIuyCGGZ1000Y3ovZAgUWGlUE4lTrAkXGiMjlAwhUmmZyeYLF0WeskNol2xnz7P5EFSCjJ8QeRbRxQ6A+7IwIj6QpgKUJoQiZxGSHZoK0SbJGedC7kPwaIuQpWIviTCYFSFEKA3qQTk7ZYzKQvDzGgqVCg6fvi2bx65h0cNeq1JyEqlU6RA+xXE8Qt68/zhD71v0U9v/q653lNHperNuGekponkNnhVysqJbYVLsylrYsmIlDSnEE4bgBe2JNlQd0wSUjg4UMi/N10vb3djzRorlqhCtd2JRI9+6c6YYmxBo86rbxLQlpGWCunH1jBmVT6n+Fmc4L4N6RB2nqgohEZyjy/QOqU9iTmn8NhtmWBRsjyOWl6+Y0V7YvW5ge21tdUO1laTn4tw4TtMYrA3TyLq8QeuWi9M1tjJKJdFyVppUSOzUc6Ck8SIekH7aeiGC55/Zm7QG6UzVXYIFHVj26KjF0VFCIjlf56b4u1bZrzHRK3fPumyIWlVE4yPqAkei27Uo+am19j70HMBvXsPYQVtbaFUiDBLbWkLINZJKs3xoXBsIYaeRPZBKae7sqA6R/N0mwKj5eGGuqalRk0pwk4SwpJ9H3Htzf6qsW+UgfuMm/oOYbSB0mdkH84pe8wx5pWdd7P28cmbwp5B9RTtbUXOXieVv2Igcd+ZIZdtN6Vmsb6DHcFfBxt/4m0UD5EuGXDAoIo0ogUkOV5mQXQWrYJ3X1xbO/A1/LwDHfQE9Jjxd+tWyD3VNxNnWR9i7bvE8AjEx8wc5/Ve3OobrGcn+1BEvjVPnCcOhWhgRPRw6NgbT8nlCkpOpVsHq3yTpnUdbOw5xsF2h0tLgdijeaaG1FHWW//KkG4kY9CI+/wUtqPSvBwsAAA==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var chatHeader = 'chat.style_chatHeader__ujru9z2';
export var chatHeaderTitle = 'chat.style_chatHeaderTitle__ujru9z3';
export var container = 'chat.style_container__ujru9z0';
export var dateLine = 'chat.style_dateLine__ujru9zb';
export var header = 'chat.style_header__ujru9z1';
export var inputContainer = 'chat.style_inputContainer__ujru9z4';
export var messageContainer = 'chat.style_messageContainer__ujru9z5';
export var messageFromMe = 'chat.style_messageFromMe__ujru9z8';
export var messageFromMeContainer = 'chat.style_messageFromMeContainer__ujru9z7';
export var messageToMe = 'chat.style_messageToMe__ujru9za';
export var messageToMeContainer = 'chat.style_messageToMeContainer__ujru9z9';
export var textarea = 'chat.style_textarea__ujru9z6';