import {
  Fragment,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaPlus } from 'react-icons/fa6';
import { IoMdSend } from 'react-icons/io';
import { Scrollbars, positionValues } from 'react-custom-scrollbars-2';
import 'moment/locale/ko';
import moment from 'moment';
import useSWRInfinite from 'swr/infinite';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, Input, Modal, Tabs, TabsProps } from 'antd';

import { PiDownloadSimpleThin } from 'react-icons/pi';

import Linkify from 'react-linkify';
import { fetcher, client } from '../axios';
import useSocket from '../hooks/useSocket';
import {
  chatHeader,
  chatHeaderTitle,
  container,
  dateLine,
  inputContainer,
  messageContainer,
  messageFromMe,
  messageFromMeContainer,
  messageToMe,
  messageToMeContainer,
  textarea,
} from '../styles/chat.style.css';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { convertFileSize } from '../utils/convertFileSize';

function makeSection(chatList: any[]) {
  const sections: { [key: string]: any[] } = {};
  chatList.forEach((chat) => {
    const monthDate = moment(chat.createdAt).format('YYYY-MM-DD');
    if (Array.isArray(sections[monthDate])) {
      sections[monthDate].push(chat);
    } else {
      sections[monthDate] = [chat];
    }
  });
  return sections;
}

function SendAlarm(params: { roomId: string }) {
  const [alarmText, setAlarmText] = useState<
    string
  >(`전문가님, 상품 결제가 발생했습니다.

- 상품 : #{상품명}
- 옵션 : #{옵션명}
- 총액 : #{결제금액}
- 결제자 : #{구매자}
- 내용 : #{전달내용}`);

  const [productName, setProductName] = useState<string>('');
  const [optionName, setOptionName] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [consumer, setConsumer] = useState<string>('');
  const [content, setContent] = useState<string>('');

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Form
        style={{ marginRight: 20, width: '50%' }}
        disabled={false}
        layout='vertical'
      >
        <Form.Item label='상품명'>
          <Input
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            style={{ color: '#000' }}
          />
        </Form.Item>
        <Form.Item label='옵션명'>
          <Input
            value={optionName}
            onChange={(e) => setOptionName(e.target.value)}
            style={{ color: '#000' }}
          />
        </Form.Item>
        <Form.Item label='결제금액'>
          <Input
            value={price}
            type='number'
            onChange={(e) => setPrice(e.target.value)}
            style={{ color: '#000' }}
          />
        </Form.Item>
        <Form.Item label='구매자'>
          <Input
            value={consumer}
            onChange={(e) => setConsumer(e.target.value)}
            style={{ color: '#000' }}
          />
        </Form.Item>
        <Form.Item label='전달내용'>
          <TextArea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={{ color: '#000' }}
          />
        </Form.Item>
        {/* <div
          style={{
            marginBottom: 10,
            textAlign: 'center',
            color: '#666',
            fontWeight: 'bold',
          }}
        >
          위 텍스트가 그대로 채팅방으로 전송됩니다. 전송 전 템플릿 항목들을 다시
          확인해주세요!
        </div>
        <div
          style={{
            marginBottom: 10,
            textAlign: 'center',
            color: '#666',
            // fontWeight: 'bold',
          }}
        >
          전송 시 전문가에게 "알림톡"이 전송됩니다.
        </div> */}
        <Form.Item>
          <Button
            onClick={() => {
              if (!window.confirm('결제 알림을 전송하시겠습니까?')) return;

              client
                .post('/chat/send/purchase', {
                  room_id: params.roomId,
                  product_name: productName,
                  option_name: optionName,
                  consumer,
                  price,
                  content,
                })
                .then((res) => {
                  if (res.status === 200 || res.status === 201) {
                    alert('알림이 성공적으로 전송되었습니다.');
                    setProductName('');
                    setOptionName('');
                    setPrice('');
                    setConsumer('');
                    setContent('');
                  }
                });
            }}
            type='primary'
            size='large'
            style={{ width: '100%' }}
          >
            결제 알림 전송
          </Button>
        </Form.Item>
      </Form>
      <Form style={{ width: '100%' }} disabled={false} layout='vertical'>
        <Form.Item label='템플릿 내용'>
          <TextArea
            style={{ height: '400px', fontSize: 16 }}
            rows={4}
            value={alarmText}
            onChange={(e) => setAlarmText(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

function RoomDetail(props: { roomId: string }) {
  const [room, setRoom] = useState<any>({});

  useEffect(() => {
    client.get(`/room/${props.roomId}/admin`).then((res) => setRoom(res.data));
  }, []);
  return (
    <Form style={{ width: '100%' }} disabled={true} layout='vertical'>
      <Form.Item label='ID'>
        <Input style={{ color: '#000' }} value={room?.id || ''} />
      </Form.Item>
      <Form.Item label='전문가 닉네임'>
        <Input style={{ color: '#000' }} value={room?.expert_nickname || ''} />
      </Form.Item>
      <Form.Item label='전문가 연락처'>
        <Input style={{ color: '#000' }} value={room?.expert_contact || ''} />
      </Form.Item>
      <Form.Item label='의뢰인 닉네임'>
        <Input style={{ color: '#000' }} value={room?.guest_nickname || ''} />
      </Form.Item>
      <Form.Item label='의뢰인 연락처'>
        <Input style={{ color: '#000' }} value={room?.guest_contact || ''} />
      </Form.Item>
      <Form.Item label='결제 유무'>
        <Input
          style={{ color: '#000' }}
          value={room?.type === 'PLANNING' ? '결제완료' : '미결제'}
        />
      </Form.Item>
      <Form.Item label='생성날짜'>
        <Input
          style={{ color: '#000' }}
          value={moment(room?.created_at).format('YYYY-MM-DD a hh:mm:ss') || ''}
        />
      </Form.Item>
      {room?.type === 'PLANNING' && (
        <Form.Item>
          <Button
            danger
            disabled={false}
            type='primary'
            style={{ float: 'right' }}
            onClick={() => {
              if (!window.confirm('결제를 취소하시겠습니까?')) return;

              client
                .post(`/room/${props.roomId}/cancel-purchase`)
                .then((res) => {
                  if (res.status === 200 || res.status === 201) {
                    alert('결제가 취소되었습니다.');
                    window.location.href = `/rooms/${props.roomId}`;
                  }
                })
                .catch((err) => {
                  alert('결제 취소에 실패했습니다.');
                  console.error(err);
                });
            }}
          >
            결제 취소
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

export function Room() {
  const params = useParams();
  const scrollbarRef = useRef<Scrollbars>(null);
  const [message, setMessage] = useState<string>('');
  const [socket] = useSocket(params.id);
  const [limit] = useState<number>(20);
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [isReceiveMsg, setIsReceiveMsg] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<string>();

  const [expertRoomLog, setExpertRoomLog] = useState<any>();
  const [generalRoomLog, setGeneralRoomLog] = useState<any>();

  const scrollHeight = useRef<number>(0);

  const { data: chatData, mutate: mutateChat, setSize } = useSWRInfinite<any[]>(
    (index) =>
      `/chat/admin?limit=${limit}&page=${index + 1}&room_id=${params.id}`,
    fetcher,
    {
      revalidateOnFocus: false,
      onSuccess(data) {
        const height = scrollbarRef?.current?.getScrollHeight();
        scrollHeight.current = height!;
        if (
          scrollbarRef.current?.getScrollHeight()! <
          scrollbarRef.current?.getClientHeight()! +
            scrollbarRef.current?.getScrollTop()! +
            150
        ) {
          setTimeout(() => {
            scrollbarRef.current?.scrollToBottom();
          }, 0);
        } else {
          if (isReceiveMsg) {
            toast.success('새 메시지가 도착했습니다.', {
              onClick() {
                scrollbarRef.current?.scrollToBottom();
              },

              closeOnClick: true,
            });
            setIsReceiveMsg(false);
          }
        }

        if (isInitial) {
          const height = scrollbarRef?.current?.getScrollHeight();
          scrollHeight.current = height!;
          setTimeout(() => {
            scrollbarRef.current?.scrollToBottom();
          }, 0);

          setIsInitial(false);

          return;
        }

        if (isReceiveMsg === false && data.length > 1) {
          setTimeout(() => {
            scrollbarRef.current?.scrollTop(
              scrollbarRef?.current?.getScrollHeight() - scrollHeight.current
            );
          }, 0);
        }
      },
      onError(err) {
        if (err.response.data?.code === 'NOT_FOUND_ROOM') {
          alert(err.response.data?.message);
          // router.push('/chats');
        }
      },
    }
  );

  const isEmpty = chatData?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (chatData && chatData[chatData.length - 1]?.length < limit);

  const onMessage = useCallback(
    ({ chat: data, roomLog }: any) => {
      setIsReceiveMsg(true);

      mutateChat((chatData: any) => {
        chatData?.[0].unshift(data);
        return chatData;
      });
      if (data.type === 'image') {
        mutateChat((chatData) => {
          chatData?.[0].unshift(data);
          return chatData;
        });
      } else if (data.type === 'file') {
        mutateChat((chatData) => {
          chatData?.[0].unshift(data);
          return chatData;
        });
      }

      localStorage.setItem(
        `${params.id}`,
        moment().format('YYYY-MM-DD HH:mm:ss')
      );
    },
    [mutateChat, setMessage]
  );

  const onSubmitForm = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!message) {
        alert('메시지를 입력해주세요.');
        return;
      }

      if (message?.trim() && chatData) {
        const savedChat = message;
        mutateChat((prevChatData) => {
          prevChatData?.[0].unshift({
            id: (chatData[0][0]?.id || 0) + 1,
            content: savedChat,
            roomId: params.id,
            userId: localStorage.getItem('user_id'),
            type: 'admin',
            createdAt: new Date(),
            updatedAt: null,
            deletedAt: null,
          });
          return prevChatData;
        }, false).then(() => {
          localStorage.setItem(
            `${params.id}`,
            moment().format('YYYY-MM-DD HH:mm:ss')
          );
          setMessage('');
          setTimeout(() => {
            scrollbarRef.current?.scrollToBottom();
          }, 0);
        });
        client
          .post(`/chat`, {
            room_id: params.id,
            content: message,
            user_id: localStorage.getItem('user_id'),
          })
          .catch(console.error);
      }
    },
    [message, params.id, chatData, mutateChat, setMessage]
  );

  const onSubmitFile = (file: File) => {
    if (!file) {
      alert('이미지 혹은 파일을 선택해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('room_id', params.id!);
    formData.append('content', '');
    formData.append('user_id', localStorage.getItem('user_id')!);
    formData.append('file', file);

    client
      .post('/chat', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        setTimeout(() => {
          scrollbarRef.current?.scrollToBottom();
        }, 0);
      })
      .catch(console.error);
  };

  const onKeydownChat = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!e.nativeEvent.isComposing && e.key === 'Enter') {
        if (!e.shiftKey) {
          e.preventDefault();
          onSubmitForm(e);
        }
      }
    },
    [onSubmitForm]
  );

  const onScroll = useCallback(
    (values: positionValues) => {
      if (values.scrollTop === 0 && !isReachingEnd && !isEmpty) {
        setIsReceiveMsg(false);
        setSize((size) => size + 1);
      }
    },
    [setSize, scrollbarRef, isReachingEnd, isEmpty]
  );

  useEffect(() => {
    // checkLoggedIn();
  }, []);

  useEffect(() => {
    socket?.on('chat', onMessage);

    socket?.emit('join', { roomId: params.id });

    socket?.on('read', ({ roomLog }) => {
      const role = roomLog?.user?.role;
      if (role === 'expert') {
        setExpertRoomLog(roomLog);
      } else if (role === 'general') {
        setGeneralRoomLog(roomLog);
      }
    });

    socket?.on('delete-chat', () => {
      mutateChat((chatData) => {
        return chatData;
      });
    });

    return () => {
      socket?.off('chat', onMessage);
    };
  }, [socket, onMessage]);

  useEffect(() => {
    client.get(`/room/${params.id}/roomlog`).then((res) => {
      const generalLog = res.data?.general;
      const expertLog = res.data?.expert;
      if (generalLog) {
        setGeneralRoomLog(generalLog);
      }

      if (expertLog) {
        setExpertRoomLog(expertLog);
      }
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(
      `${params.id}`,
      moment().format('YYYY-MM-DD HH:mm:ss')
    );
  }, [params.id]);

  const chatSections = makeSection(
    chatData ? ([] as any[]).concat(...chatData).reverse() : []
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '상세보기',
      children: <RoomDetail roomId={params.id!} />,
    },
    {
      key: '2',
      label: '결제알림 전송',
      children: <SendAlarm roomId={params.id!} />,
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <Tabs defaultActiveKey='1' items={items} onChange={() => {}} />
      </div>
      <div className={container}>
        <div className={chatHeader}>
          <div />
          <div className={chatHeaderTitle}>채팅방</div>
          <div />
        </div>

        <div className={messageContainer}>
          <ToastContainer
            position='top-right'
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
          <Scrollbars autoHide ref={scrollbarRef} onScrollFrame={onScroll}>
            {Object.entries(chatSections).map(([date, chats], i) => {
              return (
                <Fragment key={i}>
                  <div className={dateLine}>
                    {moment(date).format('YYYY년 M월 D일 dd요일')}
                  </div>

                  {/* <div style={{ fontWeight: '600', marginBottom: 5 }}>
                  결제 알림
                </div>
                <div key={i} className={messageToMeContainer}>
                  <span
                    className={messageToMe}
                    style={{
                      backgroundColor: '#ff9900',
                      color: '#fff',
                      fontWeight: '600',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <Linkify>
                      {`고객님이 결제를 진행하셨습니다.\n\n전문가님은 여행 플래닝 서비스를 제공해주시길 바라며, 불편한 점이 있으신 경우 고객센터에 문의해주시기 바랍니다.\n\n트레블마켓 고객센터: https://travelmarket.kr/how`}
                    </Linkify>
                  </span>
                  <span
                    style={{
                      color: '#797979',
                      fontSize: 11,
                      marginLeft: 5,
                    }}
                  >
                    {'오후 03:11'}
                  </span>
                </div> */}

                  {chats.map((msg: any, i) => {
                    if (msg.type === 'ADMIN') {
                      return (
                        <>
                          <div style={{ fontWeight: '600', marginBottom: 5 }}>
                            관리자
                          </div>
                          <div key={i} className={messageToMeContainer}>
                            <span
                              className={messageToMe}
                              style={{
                                backgroundColor: '#ff9900',
                                color: '#fff',
                                fontWeight: '600',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              <Linkify>{msg.content}</Linkify>
                            </span>
                            <span
                              style={{
                                color: '#797979',
                                fontSize: 11,
                                marginLeft: 5,
                              }}
                            >
                              {moment(msg.createdAt).format('a hh:mm')}
                            </span>
                          </div>
                        </>
                      );
                    }
                    if (msg.type === 'PURCHASE_ALARM') {
                      return (
                        <>
                          {/* <div style={{ fontWeight: '600', marginBottom: 5 }}>
                            결제 알림
                          </div> */}
                          <div key={i} className={messageToMeContainer}>
                            <span
                              className={messageToMe}
                              style={{
                                backgroundColor: '#ff9900',
                                color: '#fff',
                                fontWeight: '600',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              <Linkify>{msg.content}</Linkify>
                            </span>
                            <span
                              style={{
                                color: '#797979',
                                fontSize: 11,
                                marginLeft: 5,
                              }}
                            >
                              {moment(msg.createdAt).format('a hh:mm')}
                            </span>
                          </div>
                        </>
                      );
                    }
                    if (msg.userId) {
                      if (msg.type === 'image') {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              setSelectedFile(msg.content);
                              setModal(true);
                            }}
                            className={messageFromMeContainer}
                          >
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: '#f5f6f7',
                                color: '#fff',
                                borderRadius: 5,
                                width: '50%',
                                // height: 'auto',
                                height: 300,
                              }}
                            >
                              <img
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                                src={msg.content}
                                alt='chat image'
                              />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',

                                marginLeft: 5,
                              }}
                            >
                              {generalRoomLog?.readAt < msg.createdAt && (
                                <span style={{ fontSize: 11, marginBottom: 5 }}>
                                  1
                                </span>
                              )}

                              <span
                                style={{
                                  color: '#797979',
                                  fontSize: 11,
                                }}
                              >
                                {moment(msg.createdAt).format('a hh:mm')}
                              </span>
                            </div>
                          </div>
                        );
                      } else if (msg.type === 'file') {
                        return (
                          <div key={i} className={messageFromMeContainer}>
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: '#f5f6f7',
                                color: '#000',
                                borderRadius: 5,
                                width: '50%',
                                height: 80,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  wordBreak: 'break-all',
                                  width: '80%',
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-around',
                                }}
                              >
                                <div style={{ fontWeight: 'bold' }}>
                                  [첨부파일]
                                </div>
                                <div style={{}}>{msg.file_name}</div>
                                <div
                                  style={{
                                    // marginTop: 10,
                                    color: 'rgb(121, 121, 121)',
                                  }}
                                >
                                  {`용량: ${
                                    msg.size
                                      ? convertFileSize(Number(msg.size))
                                      : '0KB'
                                  }`}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '20%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <a
                                  style={{ color: '#000' }}
                                  href={msg.content}
                                  download={msg.file_name}
                                >
                                  <PiDownloadSimpleThin
                                    style={{ width: '38px', height: '38px' }}
                                  />
                                </a>
                              </div>

                              {/* <a href={msg.content}>다운로드</a> */}
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',

                                marginRight: 5,
                              }}
                            >
                              {(!generalRoomLog?.readAt ||
                                generalRoomLog?.readAt < msg.createdAt) && (
                                <span style={{ fontSize: 11, marginBottom: 5 }}>
                                  1
                                </span>
                              )}
                              <span
                                style={{
                                  color: '#797979',
                                  fontSize: 11,
                                }}
                              >
                                {moment(msg.createdAt).format('a hh:mm')}
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <>
                            <div
                              style={{
                                textAlign: 'right',
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginBottom: 5,
                              }}
                            >
                              {'전문가'}
                            </div>
                            <div key={i} className={messageFromMeContainer}>
                              <div className={messageFromMe}>
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target='blank'
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {msg.content}
                                </Linkify>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',

                                  marginRight: 5,
                                }}
                              >
                                {generalRoomLog?.readAt < msg.createdAt && (
                                  <span
                                    style={{ fontSize: 11, marginBottom: 5 }}
                                  >
                                    1
                                  </span>
                                )}

                                <span
                                  style={{
                                    color: '#797979',
                                    fontSize: 11,
                                  }}
                                >
                                  {moment(msg.createdAt).format('a hh:mm')}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      }
                    } else {
                      if (msg.type === 'image') {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              setSelectedFile(msg.content);
                              setModal(true);
                            }}
                            className={messageToMeContainer}
                          >
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: '#e1e1e1',
                                color: '#000',
                                borderRadius: 5,
                                width: '50%',
                                // height: 'auto',
                                height: 300,
                              }}
                            >
                              <img
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                                src={msg.content}
                                alt='chat image'
                              />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',

                                marginLeft: 5,
                              }}
                            >
                              {expertRoomLog?.readAt < msg.createdAt && (
                                <span style={{ fontSize: 11, marginBottom: 5 }}>
                                  1
                                </span>
                              )}

                              <span
                                style={{
                                  color: '#797979',
                                  fontSize: 11,
                                }}
                              >
                                {moment(msg.createdAt).format('a hh:mm')}
                              </span>
                            </div>
                          </div>
                        );
                      } else if (msg.type === 'file') {
                        return (
                          <div key={i} className={messageToMeContainer}>
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: '#f5f6f7',
                                color: '#000',
                                borderRadius: 5,
                                width: '50%',
                                height: 80,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  wordBreak: 'break-all',
                                  width: '80%',
                                  height: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-around',
                                }}
                              >
                                <div style={{ fontWeight: 'bold' }}>
                                  [첨부파일]
                                </div>
                                <div style={{}}>{msg.file_name}</div>
                                <div
                                  style={{
                                    // marginTop: 10,
                                    color: 'rgb(121, 121, 121)',
                                  }}
                                >
                                  {`용량: ${
                                    msg.size
                                      ? convertFileSize(Number(msg.size))
                                      : '0KB'
                                  }`}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '20%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <a
                                  style={{ color: '#000' }}
                                  href={msg.content}
                                  download={msg.file_name}
                                >
                                  <PiDownloadSimpleThin
                                    style={{ width: '38px', height: '38px' }}
                                  />
                                </a>
                              </div>

                              {/* <a href={msg.content}>다운로드</a> */}
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',

                                marginLeft: 5,
                              }}
                            >
                              {expertRoomLog?.readAt < msg.createdAt && (
                                <span style={{ fontSize: 11, marginBottom: 5 }}>
                                  1
                                </span>
                              )}

                              <span
                                style={{
                                  color: '#797979',
                                  fontSize: 11,
                                }}
                              >
                                {moment(msg.createdAt).format('a hh:mm')}
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <>
                            <div
                              style={{
                                textAlign: 'left',
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginBottom: 5,
                              }}
                            >
                              {'의뢰인'}
                            </div>
                            <div key={i} className={messageToMeContainer}>
                              <div className={messageToMe}>
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target='blank'
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {msg.content}
                                </Linkify>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',

                                  marginLeft: 5,
                                }}
                              >
                                {expertRoomLog?.readAt < msg.createdAt && (
                                  <span
                                    style={{ fontSize: 11, marginBottom: 5 }}
                                  >
                                    1
                                  </span>
                                )}

                                <span
                                  style={{
                                    color: '#797979',
                                    fontSize: 11,
                                  }}
                                >
                                  {moment(msg.createdAt).format('a hh:mm')}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  })}
                </Fragment>
              );
            })}
          </Scrollbars>
        </div>

        <div className={inputContainer}>
          <input
            // accept='image/*'
            style={{ display: 'none' }}
            id='contained-button-file'
            type='file'
            onChange={(e) => {
              const file = e.target.files![0];
              const type = file.type;

              const size = file?.size;
              if (type.includes('image')) {
                if (size > 10000000) {
                  alert('10MB 이하의 이미지만 업로드 가능합니다.');
                  return;
                }
              } else {
                if (size > 100000000) {
                  alert('100MB 이하의 파일만 업로드 가능합니다.');
                  return;
                }
              }

              onSubmitFile(e.target.files![0]);
              e.target.value = '';
            }}
          />
          <label htmlFor='contained-button-file'>
            <FaPlus
              style={{
                color: '#333333',
                width: '30px',
                height: '30px',
                marginRight: 10,
              }}
            />
          </label>

          <textarea
            className={textarea}
            value={message}
            onKeyDown={onKeydownChat}
            onChange={(e) => setMessage(e.target.value)}
            placeholder='메시지를 입력해주세요'
          />
          <IoMdSend
            onClick={onSubmitForm}
            style={{
              color: '#333333',
              width: '35px',
              height: '30px',
              marginLeft: 10,
            }}
          />
        </div>

        <Modal
          open={modal}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          onCancel={() => setModal(false)}
        >
          <img style={{ height: '100%', width: '100%' }} src={selectedFile} />
        </Modal>
      </div>
    </div>
  );
}
