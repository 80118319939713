import {
  Button,
  Pagination,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from 'antd';

import { useEffect, useState } from 'react';
import { useQuery } from '../hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import { client } from '../axios';
import moment from 'moment';

const { Title } = Typography;

const options = [
  {
    value: 'email',
    label: '이메일',
  },
  {
    value: 'name',
    label: '이름',
  },
  {
    value: 'nickname',
    label: '닉네임',
  },
  {
    value: 'phone',
    label: '연락처',
  },
];

export function Histories() {
  const query = useQuery();
  const navigate = useNavigate();

  const page = +query.get('page')! || 1;
  const limit = +query.get('limit')! || 10;

  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<any>(query.get('filter') || '');
  const [searchTerm, setSearchTerm] = useState<string>(
    query.get('search_term') || ''
  );
  const [searchType, setSearchType] = useState<string>(
    query.get('search_type') || 'email'
  );

  const columns: TableColumnsType<any> = [
    {
      title: 'uid',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '닉네임',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '신청진행단계',
      dataIndex: 'progress_status',
      key: 'progress_status',
      render: (data) =>
        data == 0
          ? '가입완료'
          : data == 1
          ? '기본정보입력완료'
          : data == 2
          ? '전문가증빙제출완료'
          : '-',
    },
    {
      title: '계정상태',
      dataIndex: 'status',
      key: 'status',
      render: (data) =>
        data == 0 ? '작성중' : data == 1 ? '승인' : data == 2 ? '반려' : '-',
    },
    {
      title: '생성일자',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (row: any) => {
        return (
          <Button
            type='primary'
            onClick={() => navigate(`/seller/history/${row.uid}`)}
          >
            상세보기
          </Button>
        );
      },
    },
  ];

  const getList = (
    _page: number | string,
    _limit: number | string,
    _searchType: string,
    _searchTerm: string,
    _filter: string
  ) => {
    client
      .get('/seller/history', {
        params: {
          page: _page,
          limit: _limit,
          search_type: _searchType,
          search_term: _searchTerm.trim(),
          filter: _filter,
        },
      })
      .then((res) => {
        setList(res.data.histories);
        setTotalCount(res.data.totalCount || 0);
      })
      .catch((err) => console.error(err));
  };

  const handleSearch = (value: string) => {
    let url = `/seller/history?page=${page}&limit=${limit}`;
    url += `&search_term=${value}&search_type=${searchType}`;
    navigate(url);
  };

  useEffect(() => {
    getList(page, limit, searchType, searchTerm, filter);
  }, [page, limit, filter, query.get('search_term'), query.get('search_type')]);

  return (
    <div>
      <Title level={3}>전문가 신청 내역 관리</Title>
      <Space.Compact style={{ float: 'right', marginBottom: 10 }}>
        <Select
          defaultValue={'expert_nickname'}
          value={searchType}
          onChange={(value) => setSearchType(value)}
          options={options}
        />
        <Search
          placeholder='검색어를 입력해주세요.'
          onChange={(e) => setSearchTerm(e.target.value)}
          onSearch={handleSearch}
        />
      </Space.Compact>
      <Table
        onChange={(_, filter) => {
          if (filter?.type) {
            setFilter(filter.type[0]);
          } else {
            setFilter('');
          }
        }}
        dataSource={list}
        pagination={false}
        columns={columns}
        rowKey={(row: any) => row.id}
      />
      <Pagination
        style={{ marginTop: 20 }}
        defaultCurrent={page}
        pageSize={limit}
        total={totalCount}
        onChange={(_page, _pageSize) => {
          let url = `/seller/history?page=${_page}&limit=${_pageSize}`;
          if (searchTerm && searchType) {
            url += `?search_term=${searchTerm}&search_type=${searchType}`;
          }

          navigate(url);
        }}
      />
    </div>
  );
}
